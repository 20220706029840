.projectsBody {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5rem;
    padding-bottom: 5rem;
}

.projects-title {
    padding: 1rem;
    font-size: 4rem;
    color: #fff !important;
}

.css-1o011v7-MuiButtonBase-root-MuiButton-root {
    color: #66e3ce !important;
}