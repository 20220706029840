.Header {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items: center;
    border-bottom-style: solid;
    border-color:aquamarine
}

.container {
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.fade-in-text {
    font-size: 4.5rem;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;    
    display: inline-block;
    color: rgb(255, 255, 255);
    animation: fadeIn linear 2000ms;
    -webkit-animation: fadeIn linear 2000ms;
    -moz-animation: fadeIn linear 2000ms;
    -o-animation: fadeIn linear 2000ms;
    -ms-animation: fadeIn linear 2000ms;
    -webkit-animation-fill-mode: forwards;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}

/*hover animation*/
.colorhover {
    background-image: linear-gradient(
        to right,
        #66e3ce,
        #66e3ce 50%,
        #fff 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none!important;
    outline: none;
}

.colorhover:before {
    content: '';
    background: #66e3ce;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    text-decoration: none;
}

.colorhover:hover {
    background-position: 0;
    text-decoration: none!important;
}

.colorhover:hover::before{
    width: 100%;
    text-decoration: none;
}

/*fade in animation*/

@keyframes fadeIn {
    0% {opacity:0;}
    20% { opacity: 0 ;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    20% { opacity: 0 ;}
    100% {opacity:1;}
  }
  
@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    20% { opacity: 0 ;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    20% { opacity: 0 ;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    20% { opacity: 0 ;}
    100% {opacity:1;}
}

/*transform up animation*/

@keyframes transformUp {
    0% { transform: translate(0%, 60%);}
    85% {transform:translate(0, 60%)}
    100% { transform: translate(0%, 0%)}
}

@-moz-keyframes transformUp {
    0% { transform: translate(0);}
    85% {transform:translate(0)}
    100% { transform: translate(0%, 0%)}
}

@-webkit-keyframes transformUp {
    0% { transform: translate(0);}
    85% {transform:translate(0)}
    100% { transform: translate(0%, 0%)}
}

@-o-keyframes transformUp {
    0% { transform: translate(0);}
    85% {transform:translate(0)}
    100% { transform: translate(0%, 0%)}
}

@-ms-keyframes transformUp {
    0% { transform: translate(0);}
    85% {transform:translate(0)}
    100% { transform: translate(0%, 0%)}
}

/*color swipe animation*/

